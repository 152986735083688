define("discourse/plugins/discourse-restore-pm-mentions/discourse/initializers/restore-admin-mentions", ["exports", "discourse/lib/plugin-api", "@ember/service", "@ember/template"], function (_exports, _pluginApi, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (!siteSettings.restore_pm_mentions_enabled) return;

    // the plugin allows mentioning @admins but we get a warning for that, so we must suppress it
    // for some reason we are unable to override controller:composer cannotSeeMention()
    // so we resort to overriding this one and add admins to the list of stuff we've already warned for
    // this effectively suppresses the warning

    // Since Discourse 3.4 that method does not work anymore
    // it yields "Uncaught (in promise) TypeError: "_warnCannotSeeMention" is read-only"
    // Luckily enough, a good friend suggested this workaround
    // "you can try modifying the method before it's used"

    api.modifyClass("component:composer-editor", {
      pluginId: "discourse-restore-pm-mentions",
      didInsertElement() {
        var _this2 = this;
        this._super(...arguments);
        let originalWarn = this._warnCannotSeeMention.bind(this);
        Object.defineProperty(this, "_warnCannotSeeMention", {
          value: function () {
            _this2.warnedCannotSeeMentions.push("admins");
            _this2.warnedCannotSeeMentions.push("administrators");
            return originalWarn(...arguments);
          },
          writable: false,
          configurable: true // Allow future modifications
        });
      }
    });
    api.modifyClass("controller:composer", {
      pluginId: "discourse-restore-pm-mentions",
      dialog: (0, _service.inject)(),
      save() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        const _this = this;
        const _super = this._super;
        if (this.currentUser.staff) {
          return this._super(...args);
        }
        let mentioned = 0;
        $(".d-editor-preview a.mention-group").each(function () {
          const group = $(this).text().toLowerCase().trim();
          if (group === "@admins" || group === "@administrators") {
            mentioned++;
          }
        });
        if (!mentioned) {
          return this._super(...args);
        }
        this.dialog.confirm({
          message: (0, _template.htmlSafe)(I18n.t("restore_pm_mentions.alert")),
          shouldDisplayCancel: false,
          confirmButtonLabel: "restore_pm_mentions.proceed",
          confirmButtonClass: "btn-danger",
          didConfirm: () => {
            return _super.call(_this, ...args);
          },
          cancelButtonLabel: "restore_pm_mentions.back",
          cancelButtonClass: "btn-default",
          didCancel: () => {
            // cancel
          }
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "restore-admin-mentions",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", initWithApi);
    }
  };
});